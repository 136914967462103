<template>
  <li class="relative col-span-1 flex flex-col justify-between text-center bg-white rounded-lg shadow">
    <ArchiveBoxXMarkIcon
      v-if="candidate.deleted_at"
      class="w-full h-full text-rose-50 absolute z-0"
    />
    <div class="relative z-1 flex flex-col justify-between h-full">
      <div class="flex-1 flex flex-col p-2">
        <img
          :src="candidate.headshot"
          alt="headshot"
          class="w-16 h-16 shrink-0 mx-auto rounded-full"
        >
        <div class="mt-1">
          <div v-if="candidate.files.length">
            <div class="shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs leading-4 font-medium bg-green-100 rounded-full">
              {{candidate.files.length}} Files
            </div>
          </div>
          <div v-else >&nbsp;</div>
        </div>
        <h3 class="mt-1 text-gray-900 text-sm leading-5 font-medium">
          {{candidate.first_name}} {{candidate.last_name}}
        </h3>
        <dl class="mt-1 grow flex flex-col justify-between">
          <dt class="sr-only">
            Email
          </dt>
          <dd class="text-gray-500 text-sm leading-5">
            {{candidate.email}}
          </dd>
          <dt class="sr-only">
            Phone
          </dt>
          <dd class="mt-3">
            {{candidate.phone}}
          </dd>
        </dl>
      </div>
      <div class="text-xs px-8 p-2">
        <slot name="extras"></slot>
      </div>
      <div class="border-t border-gray-200">
        <slot></slot>
      </div>
    </div>
  </li>
</template>

<script>
import {ArchiveBoxXMarkIcon} from '@heroicons/vue/24/outline';

export default {
  components: {
    ArchiveBoxXMarkIcon
  },
  props:['candidate']
}
</script>
