export default {

	state: {
    navOpen: false,
    profileOpen: false
	},

  mutations: {
    toggleNav(state){
      state.navOpen = !state.navOpen;
    },
    toggleProfile(state){
      state.profileOpen = !state.profileOpen;
    }
  }
}