<template>
<div class="mt-6 sm:mt-5">
  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

    <label
      :for="this.name"
      class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
    >
      {{this.label}}
    </label>

    <div class="mt-1 sm:mt-0 sm:col-span-2">
      <div class="relative  rounded-md shadow-sm">
        <input
          :id="this.name"
          :type="this.type"
          :value="this.value"
          :name="this.name"
          :dusk="this.dusk"
          class="form-input block w-full transition duration-150 ease-in-out border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
        >

          <div
            v-if="this.error"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
      </div>
      <p v-if="this.error" class="mt-2 text-sm text-red-600">{{this.error}}</p>
    </div>

  </div>
</div>
</template>
<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: false,
        default: 'text',
      },
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
      dusk: {
        type: String,
        required: false,
        default: '',
      },
      error: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>
