<template>
  <div>
    <select-single
      @single-select="onClientSelect"
      :options="clients"
      :selected-option="selectedClient"
      name="client_id"
      dusk="client"
      label="For Client"
      :error="clientError"
      placeholder="Choose a Client"
    />

    <select-multiple
      :options="filteredEndUsers"
      :selected-options="mySelectedEndusers"
      name="endusers[]"
      dusk="endusers"
      label="Client Hiring Team"
      :error="endusersError"
      placeholder="Choose End Users that own this Search"
    />
  </div>
</template>

<script>
import SelectSingle from './SelectSingle.vue';
import SelectMultiple from './SelectMultiple.vue';
export default {
  components:{
    SelectSingle,
    SelectMultiple,
  },
  props:[
    'clients',
    'selectedClient',
    'endusers',
    'selectedEndusers',
    'clientError',
    'endusersError'
  ],
  data(){
    return {
      client: this.selectedClient,
      mySelectedEndusers: this.selectedEndusers
    };
  },
  computed:{
    filteredEndUsers(){
      return this.endusers
        .filter(enduser => enduser.client.id === this.client.id);
    }
  },
  methods:{
    onClientSelect(client){
      this.client = client;
      this.mySelectedEndusers = [];
    }
  }
}
</script>
