<template>
  <!-- Profile dropdown -->
    <div class="ml-3 relative">
      <div>
        <button
          @click="toggleOpen"
          class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <img
            class="h-8 w-8 rounded-full"
            :src="user.avatar || '/images/silhouette.png'"
            alt=""
          >
        </button>
      </div>

      <transition
        enter-active-class= "transition ease-out duration-100"
        active-class= "transform opacity-0 scale-95"
        active-to-class= "transform opacity-100 scale-100"
        leave-active-class= "transition ease-in duration-75"
        leave-class= "transform opacity-100 scale-100"
        leave-to-class= "transform opacity-0 scale-95"
      >
        <div
          v-show="isOpen"
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
          <div class="py-1 rounded-md bg-white ring-1 ring-black/5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  props:['user'],
  computed:{
    isOpen(){
      return this.$store.state.profileOpen;
    }
  },
  methods:{
    toggleOpen(){
      this.$store.commit('toggleProfile');
    }
  }
}
</script>
