<template>
  <div class="flex -mx-4 flex-wrap md:flex-nowrap">
    <div class="-mx-2 mb-4">
      <button-primary
        @clicked="submitForm"
        type="submit"
        dusk="submit"
      >
        Save
      </button-primary>

      <link-secondary
        dusk="cancel"
        :href="cancelAction"
      >
        Cancel
      </link-secondary>
    </div>
    <div class="md:w-1/2 w-full mb-6 mx-1 p-4 bg-gray-200 overflow-hidden sm:rounded-lg">
      <h2 class="text-gray-600 font-semibold text-xl mb-2">
        Assigned Candidates
      </h2>
      <p
        v-if="assignedCandidates.length === 0"
        class="text-center text-xl text-gray-400"
      >
        -- No Assigned Candidates --
      </p>
      <ul class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-2">
        <candidate-card
          v-for="candidate in assignedCandidates"
          :key="candidate.id"
          :candidate="candidate"
        >
          <div class="border-t border-gray-200">
            <div class="-mt-px flex">
              <div class="w-0 flex-1 flex border-r border-gray-200">
                <button
                  @click="removeCandidate(candidate)"
                  :dusk="`remove-candidate-${candidate.id}`"
                  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-r rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                >
                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"/>
                  </svg>
                  <span class="ml-3">Remove</span>
                </button>
                <a
                  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                  :href="`/candidates/${candidate.id}/edit`"
                  :target="`candidate-${candidate.id}`"
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </candidate-card>
      </ul>
    </div>
    <div class="md:w-1/2 w-full mb-6 mx-1 p-4 bg-gray-200 overflow-hidden sm:rounded-lg">
      <h2 class="text-gray-600 font-semibold text-xl mb-2">
        Available Candidates
      </h2>
      <ul class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-2">
        <candidate-card
          v-for="candidate in availableCandidates"
          :key="candidate.id"
          :candidate="candidate"
        >
          <div class="border-t border-gray-200">
            <div class="-mt-px flex">
              <div class="w-0 flex-1 flex border-r border-gray-200">
                <button
                  @click="selectCandidate(candidate)"
                  :dusk="`assign-candidate-${candidate.id}`"
                  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-r rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                >
                  <svg class="w-5 h-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
                  </svg>
                  <span class="ml-3">Assign</span>
                </button>
                <a
                  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                  :href="`/candidates/${candidate.id}/edit`"
                  :target="`candidate-${candidate.id}`"
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </candidate-card>
      </ul>
    </div>
    <form :action="saveAction" method="POST">
      <input type="hidden" name="_token" :value="csrf">
      <input
        v-for="candidate in assignedCandidates"
        :key="candidate.id"
        type="hidden"
        name="candidates[]"
        :value="candidate.id"
      >
    </form>
  </div>
</template>

<script>
import CandidateCard from './CandidateCard.vue';
import ButtonPrimary from './ButtonPrimary.vue';
import LinkSecondary from './LinkSecondary.vue';
export default {
  components:{
    CandidateCard,
    ButtonPrimary,
    LinkSecondary,
  },
  props:[
    'candidates',
    'searchCandidates',
    'search',
    'saveAction',
    'cancelAction'
  ],
  data:function(){
    return {
      assignedCandidates: this.searchCandidates,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  },
  computed:{
    availableCandidates: function(){
      let assignedIds = this.assignedCandidates.map(c => c.id);
      return this.candidates
        .filter(c => assignedIds.includes(c.id) === false);
    }
  },
  methods:{
    selectCandidate(candidate){
      this.assignedCandidates
        .push(candidate);
    },
    removeCandidate(candidate){
      this.assignedCandidates = this.assignedCandidates
                                    .filter(c => c !== candidate);
    },
    submitForm(){
      this.$el.querySelector('form').submit();
    }
  },
}
</script>
