<template>
  <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    <label
      v-if="label"
      :for="name"
      class="block text-sm leading-5 font-medium text-gray-700 sm:mt-px sm:pt-2"
    >
      {{label}}
    </label>
    <div class="mt-2 sm:mt-0 sm:col-span-2">
      <div class="max-w-lg flex relative justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <input
          @change="filesSelected"
          :name="name"
          type="file"
          dusk="media"
          multiple="multiple"
          class="cursor-pointer absolute block opacity-0 w-full h-full inset-0 z-50"
        >
        <div class="text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p class="mt-1 text-sm text-gray-600">
            <button
              type="button"
              class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
            >
              Upload files
            </button>
            or drag and drop
          </p>

          <div>
            <p class="mt-1 text-xs text-gray-500">
              {{instructions}}
            </p>
          </div>

          <div
            class="mt-2"
            v-if="uploadProgress"
          >
            <div class="w-full bg-white">
              <div
                class="bg-blue-600 p-0.5 text-center text-xs font-medium leading-none text-white"
                :class="{barberpole: uploadProgress === 100}"
                :style="`width: ${uploadProgress}%; transition: width 300ms ease-in-out;`"
              >
                <span v-if="uploadProgress === 100">
                  Processing
                </span>
                <span v-else>
                  Uploading {{uploadProgress}}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p
        v-for="error in errors"
        :key="error"
        class="mt-2 text-sm text-red-600"
      >
        {{error[0]}}
      </p>

    </div>

    <div></div>

    <ul class="mt-2 sm:mt-0 sm:col-span-2 max-w-lg">
      <media-card-simple
        v-for="file in queuedFiles"
        :key="file.thumbnail"
        :file="file"
        :isNew="true"
      >
      </media-card-simple>
    </ul>

    <div></div>
    <ul class="mt-2 sm:mt-0 sm:col-span-2 max-w-lg">
      <media-card-simple
        v-for="file in currentFiles"
        :key="file.id"
        :file="file"
        :isSaved="true"
        :willBeDeleted="filesToDelete.includes(file)"
      >
        <template #actions>
          <button
            v-if="filesToDelete.includes(file)"
            @click.prevent.stop="unDeleteFile(file)"
            title="Do Not Delete"
            dusk="dont-remove-media"
            class="w-8 h-8 inline-flex items-center justify-center text-green-400 rounded-full bg-transparent hover:text-green-900 focus:outline-none focus:text-green-500 focus:bg-green-100 transition ease-in-out duration-150"
          >
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"/>
            </svg>
          </button>

          <button
            v-else
            @click.prevent.stop="filesToDelete.push(file)"
            title="Delete"
            dusk="remove-media"
            class="w-8 h-8 inline-flex items-center justify-center text-red-400 rounded-full bg-transparent hover:text-red-900 focus:outline-none focus:text-red-500 focus:bg-red-100 transition ease-in-out duration-150"
          >
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd"/>
            </svg>
          </button>

        </template>
      </media-card-simple>
    </ul>

    <input
      v-for="file in filesToDelete"
      :key="file.id"
      type='hidden'
      name="mediaToDelete[]"
      :value="file.id"
    >
  </div>
</template>

<script>
import MediaCardSimple from './MediaCardSimple.vue';
export default {
  components:{
    MediaCardSimple
  },
  props:{
    label:String,
    name:String,
    dusk:String,
    instructions:String,
    emitsEvents: {
      type: Boolean,
      default: false,
    },
    uploadProgress:{
      type: Number,
      default: 0,
    },
    mimes:{
      type: String,
      default: '*'
    },
    errors:{
      type:[Array, Object],
      default: () => [],
    },
    currentFiles:{
      type:Array,
      default: () => [],
    }
  },
  data(){
    return {
      queuedFiles:[],
      filesToDelete:[],
    }
  },
  methods:{
    selectFile(e){
      this.$el.querySelector('input').click();
    },
    async filesSelected(e){
      let queuedFiles = Array.from(e.target.files)
      if(this.emitsEvents){
        this.$emit('filesSelected', queuedFiles);
        e.target.value = null;
        return;
      }

      for (let i = 0; i < queuedFiles.length; i++) {
        const file = queuedFiles[i];
        file.mime_type = file.type;
        if(file.type.includes('image')){
          await this.readBase64(file);
        }
      }

      this.queuedFiles = queuedFiles;
    },

    readBase64(file){
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.onloadend = ()=>{
          file.thumbnail = reader.result
          resolve();
        }
        reader.readAsDataURL(file);
      })
    },

    unDeleteFile(file){
      this.filesToDelete = this.filesToDelete.filter(item=>item !== file);
    },
  }
}
</script>
