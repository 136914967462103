<template>
  <div :class="containerClass">
    <button
      :class="classNames
        ? classNames
        : 'text-red-600 hover:text-red-900'"
      :dusk="dusk"
      v-on:click="showModal"
    >
      {{this.buttonText}}
    </button>
    <form style="display: none;" :action="route" method="POST">
      <input type="hidden" name="_method" value="DELETE">
      <input type="hidden" name="_token" :value="csrf">
    </form>

    <SimpleModal
      @confirm="handleModalConfirm"
      @cancel="handleModalCancel"
      :title="title"
      :open="modalOpen"
    >
      {{warning}}
    </SimpleModal>
  </div>
</template>

<script>
    import SimpleModal from './SimpleModal.vue';

    export default {
        components:{
          SimpleModal
        },
        props:{
          route: {
            type: String,
          },
          title: {
            type: String,
          },
          warning: {
            type: String,
          },
          dusk: {
            type: String,
          },
          classNames: {
            type: String,
          },
          containerClass: {
            type: String,
          },
          buttonText: {
            type: String,
            default: 'Delete'
          }
        },
        data: () => ({
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          modalOpen: false,
        }),
        methods: {
          showModal(){
            this.modalOpen = true;
          },
          handleModalConfirm(){
            this.$el.querySelector('form').submit();
          },
          handleModalCancel(){
            this.modalOpen = false;
          },
        }
    }
</script>
