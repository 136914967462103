<template>
  <div
    v-bind:class="[isOpen ? 'block' : 'hidden']"
    class="border-b border-gray-700 md:hidden"
  >
    <div class="px-2 py-3 sm:px-3">
      <slot name="nav"></slot>
    </div>
    <div class="pt-4 pb-3 border-t border-gray-700">
      <div class="flex items-center px-5">
        <div class="shrink-0">
          <img
            class="h-10 w-10 rounded-full bg-white"
            :src="user.avatar || '/images/silhouette.png'"
            alt=""
          >
        </div>
        <div class="ml-3">
          <div class="text-base font-medium leading-none text-white">{{user.name}}</div>
          <div class="mt-1 text-sm font-medium leading-none text-gray-400">{{user.email}}</div>
        </div>
      </div>
      <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <slot name="profile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['user'],
  computed:{
    isOpen(){
      return this.$store.state.navOpen;
    }
  }
}
</script>
