<template>
  <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
    <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
      <div
        :class="this.backgroundColor"
        class="p-2 rounded-lg shadow-lg sm:p-3"
      >
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span
              class="flex p-2 rounded-lg"
              v-bind:class="this.iconColor"
            >

              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
              </svg>

            </span>
            <p class="ml-3 font-medium text-white truncate">
                {{message}}
            </p>
          </div>
          <div class="order-2 shrink-0 sm:order-3 sm:ml-2">
            <button
              @click="dismiss"
              type="button"
              v-bind:class="`${this.buttonHover} ${this.buttonFocus}`"
              class="-mr-1 flex p-2 rounded-md focus:outline-none transition ease-in-out duration-150"
              aria-label="Dismiss"
            >
              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['message','type'],
  methods:{
    dismiss(){
      this.$el.innerHTML = '';
    }
  },
  computed:{
    backgroundColor(){
      if(this.type === 'status'){
        return 'bg-green-600';
      }
      if(this.type === 'warning'){
        return 'bg-red-600';
      }
      return 'bg-indigo-600';
    },
    buttonHover(){
      if(this.type === 'status'){
        return 'hover:bg-green-500';
      }
      if(this.type === 'warning'){
        return 'hover:bg-red-500';
      }
      return 'hover:bg-indigo-500';
    },
    buttonFocus(){
      if(this.type === 'status'){
        return 'focus:bg-green-400';
      }
      if(this.type === 'warning'){
        return 'focus:bg-red-400';
      }
      return 'focus:bg-indigo-400';
    },
    iconColor(){
      if(this.type === 'status'){
        return 'text-green-800';
      }
      if(this.type === 'warning'){
        return 'text-red-800';
      }
      return 'text-indigo-800';
    },
  },
}
</script>
